<template>
  <div>
    <img class="welcome" role="button" @click="$emit('start')" src="../assets/title.gif" style="width: 400px;" alt="Halloween Cat Party welcome graphic" />
  </div>
</template>

<script>
export default {
  name: 'Welcome',

  data: function () {
    return {
      state: {
      }
    }
  },

  methods: {
    getImgUrl(pic) {
      return require('../assets/' + pic)
    }
  }
}
</script>

<style scoped>
.welcome {
  cursor: pointer;
}
</style>
