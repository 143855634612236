import Vue from 'vue'
import App from './App.vue'

import config from './config.json'

import 'csshake/dist/csshake.min.css'

require('./scss/_global.scss');

import Vuikit from 'vuikit'

Vue.use(Vuikit)


Vue.prototype.$config = config

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
