<template>
  <div>
    <div :class="ghostMode ? 'shake-slow shake-constant' : ''" style="position: relative; display: inline-block;">
      <div id="build" :class="ghostMode ? 'scale' : ''">
        <img :src="getImgUrl($config.base)" class="base"/>

        <div v-show="!ghostMode">
          <transition name="fade">
            <div class='part' :key="$config.legs[state.legs].title"><img :src="getImgUrl($config.legs[state.legs].image)" :alt="$config.legs[state.legs].title" /></div>
          </transition>

          <transition name="fade">
            <div class='part' :key="$config.body[state.body].title"><img :src="getImgUrl($config.body[state.body].image)" :alt="$config.body[state.body].title" /></div>
          </transition>

          <transition name="fade">
            <div class='part' :key="$config.body[state.accessory].title"><img :src="getImgUrl($config.accessory[state.accessory].image)" :alt="$config.accessory[state.accessory].title" /></div>
          </transition>

          <transition name="fade">
            <div class='part' :key="$config.body[state.head].title"><img :src="getImgUrl($config.head[state.head].image)" :alt="$config.head[state.head].title" /></div>
          </transition>

          <div role="button" data-html2canvas-ignore class='exclude arrow-button left body' title="Previous Body" @click="onShowPrevious('body')"><img src="../assets/arrow.png" /></div>
          <div role="button" data-html2canvas-ignore class='exclude arrow-button right body' title="Next Body" @click="onShowNext('body')"><img src="../assets/arrow2.png" /></div>

          <div role="button" data-html2canvas-ignore class='exclude arrow-button left head' title="Previous Head" @click="onShowPrevious('head')"><img src="../assets/arrow.png" /></div>
          <div role="button" data-html2canvas-ignore class='exclude arrow-button right head' title="Next Head" @click="onShowNext('head')"><img src="../assets/arrow2.png" /></div>

          <div role="button" data-html2canvas-ignore class='exclude arrow-button left legs' title="Previous Legs" @click="onShowPrevious('legs')"><img src="../assets/arrow.png" /></div>
          <div role="button" data-html2canvas-ignore class='exclude arrow-button right legs' title="Next Legs" @click="onShowNext('legs')"><img src="../assets/arrow2.png" /></div>

          <div role="button" data-html2canvas-ignore class='exclude arrow-button left accessory' title="Previous Accessory" @click="onShowPrevious('accessory')"><img src="../assets/arrow.png" /></div>
          <div role="button" data-html2canvas-ignore class='exclude arrow-button right accessory' title="Next Accessory" @click="onShowNext('accessory')"><img src="../assets/arrow2.png" /></div>
        </div>

        <img v-show="ghostMode" :src="getImgUrl($config.ghost)" key="ghostmode" class="part" />

        <img v-if="!ghostMode" class="attribution" src="../assets/hana.png" />
      </div>
    </div>

    <div role="button" @click="onToggleGhostMode" class="ghost-mode-button uk-flex uk-flex-middle exclude">
      <div :class="`ghost-check ${ghostMode ? 'selected' : '' }`" />
      <img src="../assets/TXT3.png" style="height: 20px" />
    </div>

    <div role="button" v-if="!ghostMode" @click="show = !show" class="download-button exclude">
      <img src="../assets/finished.png" style="height: 30px" />
    </div>

    <div v-if="!ghostMode" class="color-picker exclude uk-flex uk-flex-middle">
      <img src="../assets/BACKGROUND.png" style="height: 20px" />
      <v-swatches
        v-model="color"
        :swatches="$config.swatches"
        shapes="circles"
        show-border
      ></v-swatches>
    </div>

    <vk-modal :show.sync="show" center>
      <p><img src="../assets/DOWNLOAD OUTFIT.png" style="height: 30px" /></p>
      <p>Download your beautiful creature!</p>
      <p><a class='uk-button uk-button-primary' @click="onDownloadImage" :disabled="downloadButtonDisabled">{{ downloadButtonText }}</a></p>
      <p><img src="../assets/divider.png" style="height: 40px" /></p>
      <p><img src="../assets/BUY PRINT.png" style="height: 40px" /></p>
      <p>Use Code<br/><span class='uk-text-large uk-text-bold'>{{ shortcode }}</span><br/>in the 'Personalisation' box</p>
      <p><a :href="$config.buy_url" class='uk-button uk-button-primary' target="_blank">Go to Etsy</a></p>
    </vk-modal>

  </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import html2canvas from 'html2canvas';


export default {
  name: 'HalloPicker',
  components: { VSwatches },

  props: ['ghostMode'],

  data: function () {
    return {
      state: {
        head: 0,
        body: 0,
        legs: 0,
        accessory: 0
      },
      parts: ['head', 'body', 'legs', 'accessory'],
      color: this.$config.swatches[0],
      show: false,

      downloadButtonText: "Download",
      downloadButtonDisabled: false
    }
  },

  created() {
    let path = location.pathname.replace("/", "")
    let pathIndices = path.split('')
    console.log(pathIndices)
    for(var i = 0; i < 4; i++){
      this.state[this.parts[i]] = parseInt(pathIndices[i]) || this.state[this.parts[i]]
    }
    this.color = this.$config.swatches[parseInt(pathIndices[4]) || 0]
  },

  computed: {
    shortcode: function() {
      return  this.getShortcode()
    }
  },

  watch: {
    ghostMode: function () {
      this.updateHistoryState()
    },
    color: function(newVal) {
      this.$emit('changeColor', newVal)
      this.updateHistoryState()
    }
  },

  methods: {
    getImgUrl(pic) {
      return require('../assets/' + pic)
    },

    onShowPrevious(part) {
      if(this.state[part] === 0){
        this.state[part] = this.$config[part].length - 1
      } else {
        this.state[part]--
      }
      this.updateHistoryState()
    },

    onShowNext(part) {
      if(this.state[part] === this.$config[part].length - 1){
        this.state[part] = 0
      } else {
        this.state[part]++
      }
      this.updateHistoryState()
    },

    onToggleGhostMode() {
      this.$emit('toggleGhostMode')
    },

    onDownloadImage() {
      this.downloadButtonText = "Downloading..."
      this.downloadButtonDisabled = true

      html2canvas(document.getElementById("build"), {
        backgroundColor: this.color,
        imageTimeout: 0
      }).then(canvas => {
        var link = document.createElement('a');
        link.download = `hallocat-${this.getShortcode()}.jpg`;
        link.href = canvas.toDataURL('image/jpeg')
        link.click();
        this.downloadButtonText = "Download"
        this.downloadButtonDisabled = false
      });
    },

    updateHistoryState() {
      if(this.ghostMode){
        window.history.replaceState(null, "", this.$config.ghost_path)
      } else {
        window.history.replaceState(null, "", this.getShortcode())
      }
    },

    getShortcode() {
      let colorIndex = this.$config.swatches.findIndex(swatch => swatch === this.color)
      return `${this.state.head}${this.state.body}${this.state.legs}${this.state.accessory}${colorIndex}`
    }
  }
}
</script>

<style lang="scss">
.vue-swatches {
  line-height: 0;
  margin-left: 10px;
}
.vue-swatches__trigger {
  border: 2px solid rgba(255,255,255,0.5);
}

.attribution {
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 30px;
}

.ghost-check {
  width: 20px;
  height: 20px;
  background-image: url('../assets/GHOST MODE BOX.png');
  background-size: contain;
  display: inline-block;
  margin-right: 10px;

  &.selected {
     background-image: url('../assets/GHOST MODE BOX.png'), url('../assets/GHOST MODE CHECK.png');
     background-size: contain, contain; 
  }
}

.ghost-mode-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  cursor: pointer;
}

.download-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 10px;
  cursor: pointer;
  animation: pulse 2s infinite;

  > img {
    transition: all 0.1s ease-in;
  }

  &:hover {
    > img {
      transform: scale(1.2) rotate(-10deg);
    }
  }
}

.color-picker {
  position: absolute;
  top: 20px;
  right: 20px;
}

.base {
  max-height: calc(100vh - 40px);
}
.part {
   position: absolute;
   top: 0;
   left: 0;
}

.arrow-button {
  width: 20px;
  cursor: pointer;
  position: absolute;
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  &.left {
    left: 10px;
  }
  &.right {
    right: 10px;
  }

  &.body {
    top: 50%;
  }
  &.head {
    top: 10%;
  }
  &.legs {
    top: 60%;
  }
  &.accessory {
    top: 80%;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@keyframes walkscream {
  0% {
    transform: scale(1.0) rotate(0deg);
  }
  50% {
    transform: scale(2.0)
  }
  100% {
    transform: scale(1.0) rotate(360deg);
  }
}

.scale {
  animation: walkscream 2s linear infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(0.95);
	}
}
</style>
